// src/components/MyProductsScreen.js
import React, { useState, useEffect } from 'react';
import ConfirmDialog from '../components/ConfirmDialog';
import ProductDetailModal from "../components/ProductDetailModal";

const MyProductsScreen = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState({ isOpen: false, productId: null });
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [trendData, setTrendData] = useState({});

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await fetch('https://api.prodify-app.com/my-products', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }

            const data = await response.json();
            setProducts(data);
            console.log(data);
            // Fetch trend data for each product after fetching products
            await Promise.all(data.map(product => fetchTrendData(product)));
        } catch (error) {
            console.error('Error fetching products:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchTrendData = async (product) => {
        try {
            const token = localStorage.getItem('token');
            const shopData = JSON.parse(localStorage.getItem('shopData'));

            const response = await fetch('https://api.prodify-app.com/analyze-trends', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    tags: product.tags,
                    region: shopData?.shop_region || 'US'
                })
            });

            const data = await response.json();
            setTrendData(prev => ({
                ...prev,
                [product.id]: data.trends
            }));
        } catch (error) {
            console.error('Error fetching trend data:', error);
        }
    };

    const handleDeleteProduct = async (productId) => {
        try {
            if (!productId) {
                console.error('No product ID provided');
                return;
            }

            console.log('Attempting to delete product from user list:', productId);
            const token = localStorage.getItem('token');
            const response = await fetch(`https://api.prodify-app.com/delete-product/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to remove product');
            }

            console.log('Product removed from user list');

            // Update local state en sluit dialog
            setProducts(prevProducts => prevProducts.filter(p => p._id !== productId));
            setDeleteDialog({ isOpen: false, productId: null });
        } catch (error) {
            console.error('Error removing product from user list:', error);
            setError(error.message);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-4 text-error text-center">
                {error}
            </div>
        );
    }

    return (
      <div className="p-4 md:p-6 bg-background">
          <h2 className="text-xl md:text-2xl font-semibold mb-4 text-text-primary">My Analyzed Products</h2>
          {products.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {products.map((product) => (
                  <div
                    key={product._id}
                    className="bg-background-secondary border border-border rounded-custom shadow-lg flex flex-col h-full"
                  >
                      {product.image_urls && product.image_urls[0] && (
                        <div className="relative pt-[100%] md:pt-[75%] rounded-custom overflow-hidden">
                            <img
                              src={product.image_urls[0]}
                              alt={product.title}
                              className="absolute top-0 left-0 w-full h-full object-cover bg-background-accent p-2 rounded-custom"
                            />
                        </div>
                      )}
                      <div className="p-3 md:p-4 flex-grow">
                          <h3 className="font-medium mb-2 text-text-primary">{product.title}</h3>
                          <p className="text-text-secondary mb-2">
                              <b>Price:</b> {product.currency}{product.price}
                          </p>
                          <p className="text-text-secondary mb-2 line-clamp-2">
                              {product.description}
                          </p>
                      </div>
                      <div className="mt-4 flex space-x-2 m-5">
                          <a
                            onClick={() => setSelectedProduct(product)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex-1 bg-success hover:bg-green-300 text-text-primary px-4 py-2 rounded-custom transition-colors text-center"
                          >
                              View Product
                          </a>
                          <button
                            onClick={() => {
                                if (product._id) {
                                    setDeleteDialog({
                                        isOpen: true,
                                        productId: product._id.toString() // Ensure it is a string
                                    });
                                } else {
                                    console.error('Product has no valid ID:', product);
                                }
                            }}
                            className="bg-error hover:bg-red-300 text-text-primary px-4 py-2 rounded-custom transition-colors"
                          >
                              Delete
                          </button>
                      </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="mt-4 p-4 md:p-6">
                <p className="text-lg text-text-primary">No analyzed products yet.</p>
                <p className="text-text-secondary mt-2">
                    Start by analyzing a product image to build your history.
                </p>
            </div>
          )}

          {/* Modal */}
          {selectedProduct && (
            <ProductDetailModal
              product={selectedProduct}
              initialTrendData={trendData[selectedProduct.id]}
              onClose={() => setSelectedProduct(null)}
            />
          )}

          <ConfirmDialog
            isOpen={deleteDialog.isOpen}
            onClose={() => setDeleteDialog({isOpen: false, productId: null})}
            onConfirm={() => handleDeleteProduct(deleteDialog.productId)}
            message="Are you sure you want to delete this product?"
          />
      </div>
    );
};

export default MyProductsScreen;
