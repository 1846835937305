import create from 'zustand';
import axios from '../axiosInstance';

const useTokenStore = create((set) => ({
    tokens: null,
    totalUsed: 0,
    transactions: [],
    setTokens: (tokenData) => set({ tokens: tokenData.tokens, transactions: tokenData.transactions }), // Ensure tokens and transactions are set correctly
    updateTokens: (amount) => set((state) => ({
        tokens: state.tokens + amount,
        totalUsed: state.totalUsed + (amount < 0 ? Math.abs(amount) : 0)
    })),
    fetchTokenBalance: async () => {
        try {
            const response = await axios.get('https://api.prodify-app.com/tokens/balance');
            const { tokens, transactions } = response.data; // Use 'tokens' as per your API response
            set({
                tokens: tokens, // Correctly use 'tokens'
                transactions: transactions || [], // Ensure transactions are set; default to empty array if not present
            });
        } catch (error) {
            console.error('Error fetching token balance:', error);
        }
    }
}));

export default useTokenStore;