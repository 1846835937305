// src/components/Register.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai'; // Import icons for email and password
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon
import Logo from '../assets/ProdifyMetTekst.png';

function Register() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        shop_id: '',
    });
    const [tokens, setTokens] = useState({
        access_token: '',
        refresh_token: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const access_token = decodeURIComponent(queryParams.get('access_token') || '');
        const refresh_token = decodeURIComponent(queryParams.get('refresh_token') || '');
        const shop_id = queryParams.get('shop_id');

        if (access_token && refresh_token) {
            setTokens({ access_token, refresh_token });
        }

        if (shop_id) {
            setFormData((prevData) => ({ ...prevData, shop_id }));
        }
    }, [location.search]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const dataToSend = { ...formData, ...tokens };

        try {
            const response = await axios.post('https://api.prodify-app.com/register', dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            navigate('/login');
        } catch (error) {
            setErrorMessage('Registration failed: ' + (error.response ? error.response.data : error.message));
        } finally {
            setLoading(false);
        }
    };

    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-background">
          {/* Centered Logo */}
          <div className="flex justify-center mb-6">
              <a href={'/'} className="flex items-center space-x-2 text-primary-text">
                  <img src={Logo} alt="Logo" className="w-44 h-auto" />
              </a>
          </div>

          <div className="bg-navbar p-8 bg-background-secondary rounded-custom shadow-lg w-full max-w-md">
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-center">
                    <span>{errorMessage}</span>
                </div>
              )}
              <h2 className="text-2xl font-semibold text-center text-primary-text mb-6">
                  Register
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                      <label className="block text-secondary-text mb-2">Email</label>
                      <div className="flex items-center bg-background text-primary-text border border-secondary-text rounded-custom">
                          <AiOutlineMail className="ml-2" />
                          <input
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 bg-transparent focus:outline-none focus:border-cta-button"
                          />
                      </div>
                  </div>
                  <div>
                      <label className="block text-secondary-text mb-2">Password</label>
                      <div className="flex items-center bg-background text-primary-text border border-secondary-text rounded-custom">
                          <AiOutlineLock className="ml-2" />
                          <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 bg-transparent focus:outline-none focus:border-cta-button"
                          />
                      </div>
                  </div>
                  <div>
                      <label className="block text-secondary-text mb-2">Confirm Password</label>
                      <div className="flex items-center bg-background text-primary-text border border-secondary-text rounded-custom">
                          <AiOutlineLock className="ml-2" />
                          <input
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 bg-transparent focus:outline-none focus:border-cta-button"
                          />
                      </div>
                  </div>
                  {formData.shop_id && (
                    <p className="text-secondary-text">Shop ID: {formData.shop_id}</p>
                  )}
                  <button
                    type="submit"
                    className="w-full bg-success hover:bg-green-300 text-text-primary px-4 py-2 rounded-custom transition-colors flex items-center justify-center"
                    disabled={loading}
                  >
                      {loading ? (
                        <FaSpinner className="animate-spin mr-2" />
                      ) : (
                        'Register'
                      )}
                  </button>
              </form>
          </div>
      </div>
    );
}

export default Register;