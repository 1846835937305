import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Slider from 'react-slick';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ProductDetailModal = ({ product, onClose }) => {
    const [trendData, setTrendData] = useState(null);
    const [isTrendLoading, setIsTrendLoading] = useState(false);

    // Custom arrow components
    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button
                onClick={onClick}
                className="absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white rounded-full w-8 h-8 flex items-center justify-center transition-colors"
                style={{ left: '8px' }}
            >
                ←
            </button>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
            <button
                onClick={onClick}
                className="absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white rounded-full w-8 h-8 flex items-center justify-center transition-colors"
                style={{ right: '8px' }}
            >
                →
            </button>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    useEffect(() => {
        if (product?.tags) {
            fetchTrendData(product.tags).then(r => console.log(r));
        }
    }, [product]);

    const fetchTrendData = async (tags) => {
        try {
            const token = localStorage.getItem('token');
            const shopData = JSON.parse(localStorage.getItem('shopData'));

            const response = await fetch('https://api.prodify-app.com/analyze-trends', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    tags,
                    region: shopData?.shop_region || 'US'
                })
            });

            const data = await response.json();
            console.log('Trend data received:', data.trends);
            setTrendData(data.trends);
        } catch (error) {
            console.error('Error fetching trend data:', error);
        }
    };

    const renderTrendChart = () => {
        if (!trendData || trendData.length === 0) return null; // Check if trendData exists and is not empty

        // Check if the first item in trendData has the expected timeline structure
        if (!trendData[0].timeline || !Array.isArray(trendData[0].timeline)) return null;

        const chartData = {
            labels: trendData[0].timeline.map(point => point.date),
            datasets: trendData.map((trend, index) => {
                // Ensure each trend has a valid timeline
                const values = trend.timeline ? trend.timeline.map(point => point.value) : [];
                return {
                    label: trend.keyword,
                    data: values,
                    borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5],
                    backgroundColor: 'transparent',
                    tension: 0.4,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 4
                };
            })
        };

        const options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        color: '#333',
                        usePointStyle: true,
                        padding: 20,
                    }
                },
                title: {
                    display: true,
                    text: 'Search Trends Over Time',
                    color: '#333',
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Interest Over Time (0-100)',
                        color: '#666'
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        };

        return (
          <div className="bg-navbar p-6 rounded-custom mt-6">
              <div>
                  <Line data={chartData} options={options} />
              </div>
          </div>
        );
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-background rounded-custom w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-semibold">Product Information</h2>
                        <button 
                            onClick={onClose}
                            className="text-secondary-text hover:text-primary-text"
                        >
                            ✕
                        </button>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <div className="bg-background-accent rounded-custom p-7">
                            <div className="relative">
                                <Slider {...settings}>
                                    {product.image_urls?.map((url, index) => (
                                        <div key={index} className="relative pt-[100%]">
                                            <img 
                                                src={url} 
                                                alt={`Product ${index + 1}`}
                                                className="absolute top-0 left-0 w-full h-full object-contain rounded-custom"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>

                        <div>
                            {/*<h3 className="text-xl font-semibold mb-4">Product Information</h3>*/}
                            <ul className="space-y-3">
                                <li><strong>{product.title.charAt(0).toUpperCase() + product.title.slice(1)}</strong></li>
                                <li><strong>Description<br></br></strong> {product.description}</li>
                                <li><strong>Category<br></br></strong> {product.category}</li>
                                <li><strong>Price<br></br></strong> {product.currency}{product.price}</li>
                                <li>
                                    <strong>Tags</strong>
                                    <div className="flex flex-wrap gap-2 mt-1">
                                        {product.tags.map((tag, index) => (
                                            <span key={index} className="bg-success text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-success dark:text-green-800">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            </ul>
                            <div className="mt-6">
                        <a 
                            href={product.product_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-success hover:bg-green-300 text-text-primary px-4 py-2 rounded-custom transition-colors"
                        >
                            Shop Now
                        </a>
                    </div>
                        </div>
                    </div>

                    <div className="mt-6">
                        <h3 className="text-xl font-semibold mb-4">Market Trends</h3>
                        {isTrendLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-cta-button"></div>
                            </div>
                        ) : (
                            renderTrendChart()
                        )}
                    </div>

                    {/* <div className="mt-6">
                        <a 
                            href={product.product_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block bg-cta-button text-primary-text px-6 py-2 rounded-custom hover:bg-[#e04328] transition-colors duration-300"
                        >
                            Shop Now
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProductDetailModal;
