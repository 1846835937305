// src/axiosInstance.js
import axios from 'axios';
import useUserStore from './stores/useUserStore';

const axiosInstance = axios.create({
    baseURL: 'https://prodify-backend-iota.vercel.app/',
});

// Request Interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Voeg geen Authorization header toe voor /login en /register
        if (!config.url.includes('/login') && !config.url.includes('/register')) {
            const token = useUserStore.getState().token || localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        console.error("Request Interceptor Error:", error);
        return Promise.reject(error);
    }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            console.log("Response Interceptor: Attempting to refresh token");
            try {
                const refreshToken = useUserStore.getState().refreshToken || localStorage.getItem('refreshToken');
                console.log("Response Interceptor: Using refreshToken:", refreshToken);
                if (!refreshToken) throw new Error('No refresh token available');

                const response = await axios.post('https://api.prodify-app.com/refresh-token', { refreshToken });
                const newAccessToken = response.data.accessToken;
                console.log("Response Interceptor: Received new access token:", newAccessToken);

                // Update alleen de token in de store en localStorage
                useUserStore.getState().setToken(newAccessToken);
                localStorage.setItem('token', newAccessToken);

                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                console.error('Response Interceptor: Error during token refresh:', refreshError);
                useUserStore.getState().clearUser();
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;