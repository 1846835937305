import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/ProdifyMetTekst.png';

function Footer() {
    return (
      <footer className="bg-navbar text-primary-text py-12">
          <div className="container mx-auto px-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
                  {/* Logo and Description */}
                  <div>
                      <Link to="/">
                          <img src={Logo} alt="TikLink Logo" className="h-24 w-auto mb-4" />
                      </Link>
                      <p className="text-secondary-text max-w-md">
                          Empowering e-commerce businesses with AI-powered product analysis and market insights.
                      </p>
                  </div>

                  {/* Navigation Links */}
                  <div className="flex justify-end">
                      <div>
                          <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                          <ul className="space-y-2">
                              <li>
                                  <a href="#pricing-section" className="text-secondary-text hover:text-primary-text">
                                      Pricing
                                  </a>
                              </li>
                              <li>
                                  <a href="#contact-section" className="text-secondary-text hover:text-primary-text">
                                      Contact
                                  </a>
                              </li>
                              <li>
                                  <Link to="/login" className="text-secondary-text hover:text-primary-text">
                                      Login
                                  </Link>
                              </li>
                              <li>
                                  <Link to="/register" className="text-secondary-text hover:text-primary-text">
                                      Register
                                  </Link>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
    );
}

export default Footer;