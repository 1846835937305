// src/components/Features.js
import React, { useState } from 'react';
import prodify_listing1 from '../assets/prodify_listing1.png';
import prodify_listing2 from '../assets/prodify_listing2.png';
import prodify_listing3 from '../assets/prodify_listing3.png';

function Features() {
    const [selectedImage, setSelectedImage] = useState(null);

    const closeModal = () => setSelectedImage(null);

    return (
      <section id="features-section" className="md:py-10 bg-background scroll-mt-32">
          <div className="container mx-auto px-4 sm:px-6">
              <h2 className="text-2xl sm:text-3xl font-semibold text-center mb-8 md:mb-16 text-primary-text">
                  How It Works
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-12">
                  <div className="text-center flex flex-col">
                      <div className="flex-1 min-h-[200px]">
                          <div className="text-4xl font-bold text-cta-button mb-4">1</div>
                          <h3 className="text-xl font-semibold mb-2">Upload Product Content</h3>
                          <p className="text-secondary-text mb-6">
                              Upload any product image or video and let our advanced AI analyze it to find your next winning product.
                          </p>
                      </div>
                      <div>
                          <img
                            src={prodify_listing1}
                            alt="Upload Product"
                            className="w-full h-auto rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
                            onClick={() => setSelectedImage(prodify_listing1)}
                          />
                      </div>
                  </div>
                  <div className="text-center flex flex-col">
                      <div className="flex-1 min-h-[200px]">
                          <div className="text-4xl font-bold text-cta-button mb-4">2</div>
                          <h3 className="text-xl font-semibold mb-2">AI Analysis</h3>
                          <p className="text-secondary-text mb-6">
                              Our AI finds the product in the content and provides real-time market trend data and popularity metrics.
                          </p>
                      </div>
                      <div>
                          <img
                            src={prodify_listing2}
                            alt="AI Analysis"
                            className="w-full h-auto rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
                            onClick={() => setSelectedImage(prodify_listing2)}
                          />
                      </div>
                  </div>
                  <div className="text-center flex flex-col">
                      <div className="flex-1 min-h-[200px]">
                          <div className="text-4xl font-bold text-cta-button mb-4">3</div>
                          <h3 className="text-xl font-semibold mb-2">Trending Products</h3>
                          <p className="text-secondary-text mb-6">
                              Keep track of all your analyzed products and access curated lists of trending products to find your next winning product.
                          </p>
                      </div>
                      <div>
                          <img
                            src={prodify_listing3}
                            alt="Get Insights"
                            className="w-full h-auto rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
                            onClick={() => setSelectedImage(prodify_listing3)}
                          />
                      </div>
                  </div>
              </div>
          </div>
          {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" onClick={closeModal}>
                <img src={selectedImage} alt="Enlarged view" className="max-w-full max-h-[90vh] rounded-xl" />
            </div>
          )}
      </section>
    );
}

export default Features;