import React, { useState, useEffect } from 'react';
import { FiTrendingUp } from 'react-icons/fi';
import ProductDetailModal from '../components/ProductDetailModal';

const TrendingProductsScreen = () => {
    const [trendingProducts, setTrendingProducts] = useState([]);
    const [trendData, setTrendData] = useState({});  // Object to store trend data for each product
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    // Helper function to calculate trend score
    const calculateTrendScore = (trends) => {
        if (!trends || trends.length === 0) return 0;
        
        // Calculate average trend percentage across all tags
        const avgInterests = trends.map(trend => {
            const recentValues = trend.timeline.slice(-3).map(t => t.value);
            const oldValues = trend.timeline.slice(-6, -3).map(t => t.value);
            const recentAvg = recentValues.reduce((a, b) => a + b, 0) / recentValues.length;
            const oldAvg = oldValues.reduce((a, b) => a + b, 0) / oldValues.length;
            return ((recentAvg - oldAvg) / oldAvg) * 100;
        });
        
        return avgInterests.reduce((a, b) => a + b, 0) / avgInterests.length;
    };

    // Helper function to get trend indicator
    const getTrendIndicator = (trendScore) => {
        // if (trendScore >= 50) {
        //     return { icon: "🔥", text: "Hot", color: "text-red-500", score: trendScore.toFixed(1) };
        // } else if (trendScore >= 25) {
        //     return { icon: "📈", text: "Rising", color: "text-green-500", score: trendScore.toFixed(1) };
        // } else if (trendScore >= 0) {
        //     return { icon: "→", text: "Stable", color: "text-blue-500", score: trendScore.toFixed(1) };
        // } else {
        //     return { icon: "📉", text: "Declining", color: "text-gray-500", score: trendScore.toFixed(1) };
        // }
        return { icon: "🔥", text: "Hot", color: "text-red-500", score: 77 };
    };

    // Fetch trend data for a product
    const fetchTrendData = async (tags) => {
        try {
            const token = localStorage.getItem('token');
            const shopData = JSON.parse(localStorage.getItem('shopData'));

            const response = await fetch('https://api.prodify-app.com/analyze-trends', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    tags,
                    region: shopData?.shop_region || 'US'
                })
            });

            const data = await response.json();
            console.log('Trend data received:', data.trends);
            setTrendData(data.trends);
        } catch (error) {
            console.error('Error fetching trend data:', error);
        }
    };

    useEffect(() => {
        const fetchTrendingProducts = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');
                const response = await fetch('https://api.prodify-app.com/trending-products', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch trending products');
                }

                const data = await response.json();
                console.log(data);
                const uniqueCategories = [...new Set(data.map(product => product.category))];
                setTrendingProducts(data);
                setCategories(uniqueCategories);

                // Fetch trend data for each product
                data.forEach(product => console.log(product.tags));
                data.forEach(product => fetchTrendData(product.tags));
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTrendingProducts();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cta-button"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-4 text-red-500 text-center">
                {error}
            </div>
        );
    }

    return (
        <div className="p-4 md:p-6">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-6">
                <div className="flex items-center mb-4 md:mb-0">
                    <FiTrendingUp className="text-xl md:text-2xl text-cta-button mr-2" />
                    <h2 className="text-xl md:text-2xl font-semibold">Trending Products</h2>
                </div>
            </div>

            {categories.map(category => (
                <div key={category} className="mb-6 md:mb-8">
                    <h3 className="text-lg md:text-xl font-semibold mb-4">{category}</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {trendingProducts
                            .filter(product => product.category === category)
                            .map((product, index) => {
                                const productTrends = trendData[product.id];
                                const trendScore = productTrends ? calculateTrendScore(productTrends) : null;
                                const trendIndicator = trendScore !== null ? getTrendIndicator(trendScore) : null;
                                console.log(product.image_urls);
                                
                                return (
                                  <div key={index}
                                       className="border border-border rounded-custom shadow-sm overflow-hidden bg-background-secondary flex flex-col h-full">
                                      {product.image_urls && product.image_urls[0] && (
                                        <div className="relative pt-[100%] rounded-custom overflow-hidden">
                                            <img
                                              src={product.image_urls[0]}
                                              alt={product.title}
                                              className="absolute top-0 left-0 w-full h-full object-contain cursor-pointer bg-background-accent p-6 rounded-custom"
                                              onClick={() => setSelectedProduct(product)}
                                              onError={(e) => {
                                                  e.target.src = '/path/to/fallback/image.jpg';
                                              }}
                                            />
                                        </div>
                                      )}
                                      <div className="p-4 flex flex-col flex-grow">
                                          <div className="flex items-center justify-between mb-2">
                                              <h3 className="text-xl font-semibold">{product.title}</h3>
                                              {trendIndicator && (
                                                <div
                                                  className={`flex items-center gap-1 ${trendIndicator.color} bg-opacity-10 px-2 py-1 rounded-full`}>
                                                    <span className="text-lg">{trendIndicator.icon}</span>
                                                    <span className="text-sm font-medium">{trendIndicator.text}</span>
                                                    <span className="text-xs ml-1">({trendIndicator.score}%)</span>
                                                </div>
                                              )}
                                          </div>
                                          <p className="text-secondary-text mb-2">
                                              <b>Price</b> {product.currency}{product.price}
                                          </p>
                                          <p className="text-secondary-text mb-2 line-clamp-2">
                                              {product.description}
                                          </p>
                                          <div className="flex flex-wrap gap-2 mb-4">
                                              {product.tags.map((tag, index) => (
                                                <span
                                                  key={index}
                                                  className="bg-success text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-success dark:text-green-800"
                                                >
                    {tag}
                </span>
                                              ))}
                                          </div>
                                      </div>
                                      <div className="flex gap-2 mt-auto p-5">
                                          <button
                                            onClick={() => setSelectedProduct(product)}
                                            className="bg-gray-300 hover:bg-success text-text-primary px-4 py-2 rounded-custom transition-colors"
                                          >
                                              View Details
                                          </button>
                                          <a
                                            href={product.product_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="bg-success hover:bg-green-300 text-text-primary px-4 py-2 rounded-custom transition-colors"
                                          >
                                              Shop Now
                                          </a>
                                      </div>
                                  </div>
                                );
                            })}
                    </div>
                </div>
            ))}

            {/* Modal */}
            {selectedProduct && (
              <ProductDetailModal
                product={selectedProduct}
                initialTrendData={trendData[selectedProduct.id]}
                onClose={() => setSelectedProduct(null)}
              />
            )}
        </div>
    );
};

export default TrendingProductsScreen;
