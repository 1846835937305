// src/components/ObjectDetection.js
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import VideoUpload from '../components/VideoUpload';
import { Line } from 'react-chartjs-2';
import InsufficientTokensModal from "../components/InsufficientTokensModal";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import useTokenStore from '../components/tokenStore';
// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ObjectDetection = () => {
    const [step, setStep] = useState(1);
    const [uploadType, setUploadType] = useState('');
    const [file, setFile] = useState(null);
    const [predictions, setPredictions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoFrames, setVideoFrames] = useState([]);
    const [frames, setFrames] = useState([]);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [trendData, setTrendData] = useState([]);
    const [isTrendLoading, setIsTrendLoading] = useState(false);
    const navigate = useNavigate();
    const { updateTokens } = useTokenStore();
    const [dragActive, setDragActive] = useState(false);
    const [showInsufficientTokensModal, setShowInsufficientTokensModal] = useState(false);
    const {tokens} = useTokenStore();

    // Verwijder ongebruikte states
    // const [tikTokLink, setTikTokLink] = useState('');
    // const [videoUrl, setVideoUrl] = useState(null);
    // const [showFrameSelector, setShowFrameSelector] = useState(false);
    // const [tikTokVideos, setTikTokVideos] = useState([]);
    // const [isLoadingVideos, setIsLoadingVideos] = useState(false);

    // Voeg de settings definitie weer toe voor de product images slider
    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
          <button
            onClick={onClick}
            className="absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white rounded-full w-8 h-8 flex items-center justify-center transition-colors"
            style={{ left: '8px' }}
          >
              ←
          </button>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
          <button
            onClick={onClick}
            className="absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white rounded-full w-8 h-8 flex items-center justify-center transition-colors"
            style={{ right: '8px' }}
          >
              →
          </button>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    useEffect(() => {
        return () => {
            // Cleanup URLs when component unmounts
            if (selectedFrame) {
                URL.revokeObjectURL(selectedFrame);
            }
        };
    }, []);

    // When user selects between image, video, or TikTok link
    const handleUploadTypeChange = (e) => {
        setUploadType(e.target.value);
        setFile(null);
        setPredictions(null);
        setError(null);
        setStep(2); // Move to next step
    };

    const handleFileSelect = (file) => {
        setError(null);

        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
            if (!allowedTypes.includes(file.type)) {
                setError('Please upload a valid image format (JPG, PNG, WebP)');
                setFile(null);
                setUploadedImage(null);
                return;
            }

            if (uploadedImage) {
                URL.revokeObjectURL(uploadedImage);
            }

            setFile(file);
            setUploadedImage(URL.createObjectURL(file));
        }
    };

    // Object detection for images
    const handleImageDetection = async () => {
        if (!file) {
            setError('Upload an image to proceed.');
            return;
        }

        if (!tokens || tokens < 1) {
            setShowInsufficientTokensModal(true);
            return;
        }

        setIsLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://api.prodify-app.com/analyze-image', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to analyze frame');
            }

            const data = await response.json();
            setPredictions(data);

            if (data.matches?.length === 1) {
                await saveProduct(data.matches[0]);
                await fetchTrendData(data.matches[0].tags);
                setStep(5);
                updateTokens(-1); // Only deduct token on success
            }
            else if (data.matches?.length > 1) {
                setStep(4);
                updateTokens(-1); // Only deduct token on success
            }
            else {
                setError('No matching products found');
                setStep(6); // New step for product suggestion form
            }

        } catch (error) {
            console.error('Error analyzing frame:', error);
            if (error.response?.status === 402) {
                setError('Insufficient tokens. Please purchase more tokens to continue.');
                navigate('/dashboard?tab=tokens');
            } else {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleFrameSelected = async (frameBlob) => {
        if (!tokens || tokens < 1) {
            setShowInsufficientTokensModal(true);
            return;
        }

        setIsLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('image', frameBlob);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://api.prodify-app.com/analyze-image', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                if (response.status === 402) {
                    throw new Error('Insufficient tokens');
                }
                throw new Error('Failed to analyze frame');
            }

            const data = await response.json();
            setPredictions(data);

            // Update tokens na succesvolle analyse
            updateTokens(-1);

            if (data.matches?.length === 1) {
                await saveProduct(data.matches[0]);
                await fetchTrendData(data.matches[0].tags);
                setStep(5);
            } else if (data.matches?.length > 1) {
                setStep(4);
            } else {
                setError('No matching products found');
            }
        } catch (error) {
            console.error('Error analyzing frame:', error);
            if (error.message === 'Insufficient tokens') {
                setError('Insufficient tokens. Please purchase more tokens to continue.');
                navigate('/dashboard?tab=tokens');
            } else {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTrendData = async (tags) => {
        try {
            setIsTrendLoading(true);
            const token = localStorage.getItem('token');
            const shopData = JSON.parse(localStorage.getItem('shopData'));

            const response = await fetch('https://api.prodify-app.com/analyze-trends', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    tags,
                    region: shopData?.shop_region || 'US'
                })
            });

            const data = await response.json();
            console.log('Trend data received:', data.trends);
            setTrendData(data.trends);
        } catch (error) {
            console.error('Error fetching trend data:', error);
        } finally {
            setIsTrendLoading(false);
        }
    };

    useEffect(() => {
        if (predictions?.tags) {
            fetchTrendData(predictions.tags);
        }
    }, [predictions]);

    // Add this to your render method where you want to show the trends
    const renderTrendSection = () => {
        if (!trendData || trendData.length === 0) return null;

        const chartData = {
            labels: trendData[0].timeline.map(point => point.date),
            datasets: trendData.map((trend, index) => ({
                label: trend.keyword,
                data: trend.timeline.map(point => point.value),
                borderColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF'
                ][index % 5],
                backgroundColor: 'transparent',
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 4
            }))
        };

        const options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        color: '#333',
                        usePointStyle: true,
                        padding: 20,
                    }
                },
                title: {
                    display: true,
                    text: 'Search Trends Over Time',
                    color: '#333',
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                tooltip: {
                    mode: 'index',
                    intersect: false
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Interest Over Time (0-100)',
                        color: '#666'
                    }
                },
                x: {
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Date',
                        color: '#666'
                    }
                }
            }
        };

        return (
          <div className="mt-8">
              <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-semibold">Market Trend Analysis</h3>
                  <span className="text-sm text-secondary-text">
                        Region: {trendData[0].region || 'NL'}
                    </span>
              </div>
              {isTrendLoading ? (
                <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-cta-button"></div>
                </div>
              ) : (
                <div className="bg-navbar p-6 rounded-custom shadow-lg">
                    <div className="h-[400px]">
                        <Line data={chartData} options={options} />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
                        {trendData.map((trend, index) => (
                          <div key={index} className="bg-background p-4 rounded-custom">
                              <h4 className="font-medium mb-2 flex items-center">
                                        <span
                                          className="w-3 h-3 rounded-full mr-2"
                                          style={{
                                              backgroundColor: chartData.datasets[index].borderColor
                                          }}
                                        ></span>
                                  {trend.keyword}
                              </h4>
                              <div className="text-sm space-y-1">
                                  <p>Average Interest: {trend.avgInterest}/100</p>
                                  <p className="flex items-center">
                                      Trend:
                                      <span className={`ml-1 ${trend.trendPercentage >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                                {trend.trendPercentage >= 0 ? '↑' : '↓'}
                                          {Math.abs(trend.trendPercentage)}%
                                            </span>
                                  </p>
                              </div>
                          </div>
                        ))}
                    </div>
                </div>
              )}
          </div>
        );
    };

    const handleProductSelection = async (selectedProduct) => {
        try {
            // Fetch trend data voor het geselecteerde product
            await fetchTrendData(selectedProduct.tags);

            // Sla het geselecteerde product altijd op
            await saveProduct(selectedProduct);

            // Update predictions om alleen het geselecteerde product te tonen
            setPredictions({
                matches: [selectedProduct]
            });

            // Ga naar de product details stap
            setStep(5);

        } catch (error) {
            console.error('Error selecting product:', error);
            setError(error.message);
        }
    };

    const renderTrendChart = () => {
        if (!trendData || trendData.length === 0) return null;

        const chartData = {
            labels: trendData[0].timeline.map(point => point.date),
            datasets: trendData.map((trend, index) => ({
                label: trend.keyword,
                data: trend.timeline.map(point => point.value),
                borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5],
                backgroundColor: 'transparent',
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 0,
                pointHoverRadius: 4
            }))
        };

        const options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        color: '#333',
                        usePointStyle: true,
                        padding: 20,
                    }
                },
                title: {
                    display: true,
                    text: 'Search Trends Over Time',
                    color: '#333',
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    max: 100,
                    title: {
                        display: true,
                        text: 'Interest Over Time (0-100)',
                        color: '#666'
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        };
        return (
          <div className="bg-navbar p-6 rounded-custom mt-6">
              <div className="h-[300px]">
                  <Line data={chartData} options={options} />
              </div>
          </div>
        );
    };

    const saveProduct = async (product) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch('https://api.prodify-app.com/save-product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    ...product,
                    savedAt: new Date().toISOString()
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to save product');
            }

            console.log('Product saved successfully:', data);
            return data;

        } catch (error) {
            console.error('Error saving product:', error);
            throw error;
        }
    };

    const renderProductSelection = () => {
        if (!predictions?.matches) return null;

        return (
          <div className="container mx-auto px-4 py-8">
              <div className="space-y-8">
                  {/* Uploaded Image Section */}
                  <div className="bg-background-secondary p-6 rounded-custom border-2 border-primary">
                      <h2 className="text-xl md:text-2xl font-semibold mb-4">Your Uploaded Image</h2>
                      <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                          <img
                            src={uploadedImage || selectedFrame}
                            alt="Uploaded"
                            className="w-full md:w-[200px] h-[200px] object-contain rounded-custom border-2 border-border mb-4 md:mb-0"
                          />
                          <div className="text-text-secondary">
                              <p className="text-base md:text-lg mb-2">✨ We found {predictions.matches.length} matching products</p>
                              <p className="text-sm md:text-base">Select the best match from the products below</p>
                          </div>
                      </div>
                  </div>

                  {/* Matching Products Grid */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      {predictions.matches.map((product, index) => (
                        <div key={index} className="bg-background-secondary p-6 rounded-custom border-2 border-border hover:border-primary transition-colors">
                            <div className="relative aspect-square mb-4">
                                <Slider {...settings}>
                                    {product.image_urls.map((url, imgIndex) => (
                                      <div key={imgIndex} className="outline-none">
                                          <img
                                            src={url}
                                            alt={`Product ${imgIndex + 1}`}
                                            className="w-full h-[300px] object-contain"
                                          />
                                      </div>
                                    ))}
                                </Slider>
                            </div>
                            <h3 className="text-lg font-semibold mb-2 line-clamp-2">{product.title}</h3>
                            <p className="text-text-secondary mb-4 line-clamp-3">{product.description}</p>
                            <div className="flex flex-wrap gap-2 mb-4">
                                {product.tags.map((tag, tagIndex) => (
                                  <span
                                    key={tagIndex}
                                    className="bg-primary/10 text-primary px-3 py-1 rounded-full text-sm"
                                  >
                                            {tag}
                                        </span>
                                ))}
                            </div>
                            <div className="flex items-center justify-between gap-4">
                                <button
                                  onClick={() => handleProductSelection(product)}
                                  className="flex-1 bg-primary hover:bg-primary-hover text-text-primary py-2 rounded-custom transition-colors font-semibold text-sm"
                                >
                                    Select This Product
                                </button>
                                <a
                                  href={product.product_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="px-3 py-2 border-2 border-primary text-primary hover:bg-primary hover:text-text-primary rounded-custom transition-colors text-sm"
                                >
                                    View
                                </a>
                            </div>
                        </div>
                      ))}
                  </div>
              </div>
          </div>
        );
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const droppedFile = e.dataTransfer.files[0];
        handleFileSelect(droppedFile);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        handleFileSelect(file);
    };

    const ProductSuggestionForm = () => {
        const [formData, setFormData] = useState({
            productName: '',
            description: '',
            category: '',
            brand: '',
            additionalInfo: ''
        });

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoading(true);

            try {
                const token = localStorage.getItem('token');
                await fetch('https://api.prodify-app.com/suggest-product', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...formData,
                        imageUrl: uploadedImage
                    })
                });

                // Reset all states and return to analyze product screen
                setFile(null);
                setUploadedImage(null);
                setPredictions(null);
                setError(null);
                setStep(2);

            } catch (error) {
                console.error('Error submitting product suggestion:', error);
                setError('Failed to submit product suggestion');
            } finally {
                setIsLoading(false);
            }
        };

        return (
          <div className="max-w-2xl mx-auto pt-4 md:pt-8 px-4">
              <div className="bg-background-secondary border-2 border-border rounded-custom p-4 md:p-8">
                  <h2 className="text-xl font-semibold mb-4">Help Us Improve Our Product Database</h2>
                  <p className="text-text-secondary mb-6">
                      We couldn't find this product in our database. Please provide additional information to help us add it.
                  </p>

                  <div className="mb-6">
                      <img
                        src={uploadedImage}
                        alt="Uploaded Product"
                        className="w-48 h-48 object-cover rounded-lg mx-auto"
                      />
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-4">
                      <div>
                          <label className="block text-sm font-medium text-text-primary mb-1">
                              Product Name*
                          </label>
                          <input
                            type="text"
                            value={formData.productName}
                            onChange={(e) => setFormData({...formData, productName: e.target.value})}
                            className="w-full p-2 rounded-lg border border-border bg-background"
                            required
                          />
                      </div>

                      <div>
                          <label className="block text-sm font-medium text-text-primary mb-1">
                              Description*
                          </label>
                          <textarea
                            value={formData.description}
                            onChange={(e) => setFormData({...formData, description: e.target.value})}
                            className="w-full p-2 rounded-lg border border-border bg-background h-24"
                            required
                          />
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                          <div>
                              <label className="block text-sm font-medium text-text-primary mb-1">
                                  Category*
                              </label>
                              <input
                                type="text"
                                value={formData.category}
                                onChange={(e) => setFormData({...formData, category: e.target.value})}
                                className="w-full p-2 rounded-lg border border-border bg-background"
                                required
                              />
                          </div>
                          <div>
                              <label className="block text-sm font-medium text-text-primary mb-1">
                                  Brand
                              </label>
                              <input
                                type="text"
                                value={formData.brand}
                                onChange={(e) => setFormData({...formData, brand: e.target.value})}
                                className="w-full p-2 rounded-lg border border-border bg-background"
                              />
                          </div>
                      </div>

                      <div className="flex justify-end space-x-4 mt-6">
                          <button
                            type="button"
                            onClick={() => setStep(2)}
                            className="px-4 py-2 text-text-secondary hover:text-text-primary transition-colors"
                          >
                              Cancel
                          </button>
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-primary hover:bg-primary-hover disabled:bg-primary/50 text-text-primary px-6 py-2 rounded-lg transition-colors"
                          >
                              {isLoading ? 'Submitting...' : 'Submit Product'}
                          </button>
                      </div>
                  </form>
              </div>
          </div>
        );
    };

    return (
      <div className="container mx-auto px-4 py-8 bg-background">
          {/* Step 1: Choose upload type */}
          {step === 1 && (
            <div className="container mx-auto px-4 pt-4 md:pt-20">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-xl md:text-2xl font-semibold mb-6 text-center">Choose Upload Method</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                        {/* Image Upload Card */}
                        <div
                          onClick={() => handleUploadTypeChange({target: {value: 'image'}})}
                          className="bg-background-secondary border-2 border-border rounded-custom p-8 flex flex-col items-center cursor-pointer transition-all duration-300 hover:border-primary hover:shadow-xl"
                        >
                            <div className="w-24 h-24 bg-primary/10 rounded-full flex items-center justify-center mb-6">
                                <svg
                                  className="w-12 h-12 text-primary"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    />
                                </svg>
                            </div>
                            <h3 className="text-2xl font-semibold mb-3 text-text-primary">Upload Image</h3>
                            <p className="text-text-secondary text-center">
                                Upload a single image to analyze your product
                            </p>
                        </div>

                        {/* Video Upload Card */}
                        <div
                          onClick={() => handleUploadTypeChange({target: {value: 'video'}})}
                          className="bg-background-secondary border-2 border-border rounded-custom p-8 flex flex-col items-center cursor-pointer transition-all duration-300 hover:border-primary hover:shadow-xl"
                        >
                            <div className="w-24 h-24 bg-primary/10 rounded-full flex items-center justify-center mb-6">
                                <svg
                                  className="w-12 h-12 text-primary"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                                    />
                                </svg>
                            </div>
                            <h3 className="text-2xl font-semibold mb-3 text-text-primary">Upload Video</h3>
                            <p className="text-text-secondary text-center">
                                Upload a video and select the best frame to analyze
                            </p>
                        </div>

                        {/* Locked Import from Shop Card */}
                        <div
                          className="bg-background-secondary border-2 border-border rounded-custom p-8 flex flex-col items-center opacity-50 cursor-not-allowed">
                            <div className="w-24 h-24 bg-primary/10 rounded-full flex items-center justify-center mb-6">
                                {/* Import SVG Icon */}
                                <svg
                                  className="w-12 h-12 text-primary"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M3 7h18M3 12h18M3 17h18"
                                    />
                                </svg>
                            </div>
                            <h3 className="text-2xl font-semibold mb-3 text-text-primary text-center">Import Products From Shop</h3>
                            <p className="text-text-secondary text-center">Coming soon...</p>
                        </div>
                    </div>
                </div>
            </div>
          )}

          {/* Step 2: Upload content */}
          {step === 2 && uploadType === 'image' && (
            <div className="max-w-2xl mx-auto pt-4 md:pt-20 px-4">
                <div className="bg-background-secondary border-2 border-border rounded-custom p-4 md:p-8">
                    <div className="relative">
                        <button
                          onClick={() => {
                              setStep(1);
                              setUploadedImage(null);
                              setFile(null);
                              setError(null);
                          }}
                          className="text-primary hover:text-primary-hover transition-colors flex items-center space-x-2 mb-4"
                        >
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M19 12H5M12 19l-7-7 7-7"/>
                            </svg>
                            <span>Back</span>
                        </button>

                        <div
                          className={`border-2 border-dashed rounded-lg p-8 text-center ${
                            dragActive ? 'border-primary bg-primary/5' : 'border-border'
                          }`}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                              className="hidden"
                              id="image-upload"
                            />
                            {!uploadedImage ? (
                              <label
                                htmlFor="image-upload"
                                className="cursor-pointer flex flex-col items-center justify-center"
                              >
                                  <svg className="w-12 h-12 text-gray-400 mb-4" xmlns="http://www.w3.org/2000/svg"
                                       fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                                  </svg>
                                  <p className="text-base text-gray-600">
                                      Drag & drop your image here, or <span className="text-primary">browse</span>
                                  </p>
                                  <p className="text-sm text-gray-500 mt-2">
                                      Supports: JPG, PNG, WebP
                                  </p>
                              </label>
                            ) : (
                              <div className="relative group">
                                  <img
                                    src={uploadedImage}
                                    alt="Preview"
                                    className="max-h-64 mx-auto rounded-lg"
                                  />
                                  <div
                                    className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                                      <label htmlFor="image-upload"
                                             className="cursor-pointer text-white hover:text-primary transition-colors">
                                          Click to change image
                                      </label>
                                  </div>
                              </div>
                            )}
                        </div>

                        {error && (
                          <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
                              <span>{error}</span>
                          </div>
                        )}

                        {file && (
                          <div className="mt-4">
                              <button
                                onClick={handleImageDetection}
                                disabled={isLoading}
                                className="w-full bg-primary hover:bg-primary-hover disabled:bg-primary/50 text-text-primary px-4 py-2 rounded-custom transition-colors flex items-center justify-center space-x-2"
                              >
                                  {isLoading ? (
                                    <>
                                        <svg className="animate-spin h-5 w-5 text-white"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span>Analyzing Image...</span>
                                    </>
                                  ) : (
                                    <span>Analyze Image</span>
                                  )}
                              </button>
                          </div>
                        )}

                    </div>
                </div>
            </div>
          )}

          {/* Step 2: Video Upload */}
          {step === 2 && uploadType === 'video' && (
            <div className="max-w-2xl mx-auto pt-4 md:pt-20 px-4">
                <div className="bg-background-secondary border-2 border-border rounded-custom p-4 md:p-8">
                    <VideoUpload
                      onFrameSelected={handleFrameSelected}
                      isLoading={isLoading}
                      onBack={() => setStep(1)}
                      predictions={predictions}
                      trendData={trendData}
                      chartOptions={{
                          responsive: true,
                          maintainAspectRatio: false,
                          interaction: {
                              mode: 'index',
                              intersect: false,
                          },
                          plugins: {
                              legend: {
                                  position: 'top',
                                  labels: {
                                      color: '#333',
                                      usePointStyle: true,
                                      padding: 20,
                                  }
                              },
                              title: {
                                  display: true,
                                  text: 'Search Trends Over Time',
                                  color: '#333',
                                  font: {
                                      size: 16,
                                      weight: 'bold'
                                  }
                              }
                          },
                          scales: {
                              y: {
                                  beginAtZero: true,
                                  max: 100,
                                  title: {
                                      display: true,
                                      text: 'Interest Over Time (0-100)',
                                      color: '#666'
                                  }
                              },
                              x: {
                                  grid: {
                                      display: false
                                  }
                              }
                          }
                      }}
                    />
                </div>
            </div>
          )}


          {/* Step 4: Show Results */}
          {step === 4 && renderProductSelection()}

          {/* Step 5: Product Details with Trends */}
          {step === 5 && predictions?.matches?.[0] && (
            <div className="max-w-6xl mx-auto px-4 md:px-6 pt-4 md:pt-6">
                <div className="bg-background-secondary p-4 md:p-6 rounded-custom border-2 border-border">
                    <div className="flex flex-col space-y-6">
                        {/* Product Info Section */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            {/* Left: Product Images */}
                            <div className="h-[250px] mt-12 md:mt-0">
                                <Slider {...settings}>
                                    {predictions?.matches?.[0]?.image_urls?.map((url, index) => (
                                      <div key={index} className="h-[250px]">
                                          <img
                                            src={url}
                                            alt={`Product ${index + 1}`}
                                            className="w-full h-full object-contain rounded-custom"
                                            onError={(e) => {
                                                console.error('Image failed to load:', url);
                                                e.target.src = '/fallback-image.jpg';
                                            }}
                                          />
                                      </div>
                                    )) || (
                                      // Fallback als er geen afbeeldingen zijn
                                      <div className="h-[250px]">
                                          <img
                                            src="/fallback-image.jpg"
                                            alt="No product image available"
                                            className="w-full h-full object-contain rounded-custom"
                                          />
                                      </div>
                                    )}
                                </Slider>
                            </div>

                            {/* Right: Product Info */}
                            <div className="flex flex-col space-y-3">
                                <h2 className="text-xl md:text-2xl font-semibold">{predictions.matches[0].title}</h2>
                                <p className="text-text-secondary line-clamp-2">{predictions.matches[0].description}</p>
                                <p
                                  className="text-xl font-semibold">{predictions.matches[0].currency}{predictions.matches[0].price}</p>
                                <div className="flex flex-wrap gap-2">
                                    {predictions.matches[0].tags?.map((tag, index) => (
                                      <span key={index} className="bg-gray-100 px-2 py-1 rounded-full text-sm">
                                                {tag}
                                            </span>
                                    ))}
                                </div>
                                <a
                                  href={predictions.matches[0].product_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-block bg-primary hover:bg-primary-hover text-text-primary px-6 py-2 rounded-custom transition-colors mt-2"
                                >
                                    View Product
                                </a>
                            </div>
                        </div>

                        {/* Chart section */}
                        <div className="border-t border-border pt-4 md:pt-6">
                            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                                <h3 className="text-xl font-semibold mb-2 md:mb-0">Market Trends</h3>
                                <span className="text-sm text-text-secondary">
                                        Region: {trendData?.[0]?.region || 'NL'}
                                    </span>
                            </div>
                            <div className="h-[300px] md:h-[400px]">
                                <Line
                                  data={{
                                    labels: trendData?.[0]?.timeline?.map(point => point.date) || [],
                                    datasets: trendData ? trendData.map((trend, index) => ({
                                        label: trend.keyword,
                                        data: trend?.timeline?.map(point => point.value) || [],
                                        borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'][index % 5],
                                        backgroundColor: 'transparent',
                                        tension: 0.4,
                                        borderWidth: 2,
                                        pointRadius: 0,
                                        pointHoverRadius: 4
                                    })) : []
                                }}
                                  options={{
                                      responsive: true,
                                      maintainAspectRatio: false,
                                      interaction: {
                                          mode: 'index',
                                          intersect: false,
                                      },
                                      plugins: {
                                          legend: {
                                              position: 'top',
                                              labels: {
                                                  color: '#333',
                                                  usePointStyle: true,
                                                  padding: 20,
                                              }
                                          },
                                          title: {
                                              display: true,
                                              text: 'Search Trends Over Time',
                                              color: '#333',
                                              font: {
                                                  size: 16,
                                                  weight: 'bold'
                                              }
                                          }
                                      },
                                      scales: {
                                          y: {
                                              beginAtZero: true,
                                              max: 100,
                                              title: {
                                                  display: true,
                                                  text: 'Interest Over Time (0-100)',
                                                  color: '#666'
                                              }
                                          },
                                          x: {
                                              grid: {
                                                  display: false
                                              }
                                          }
                                      }
                                  }}
                                />
                            </div>

                            {/* Nieuwe Analyze Another Product button */}
                            <div className="flex justify-center mt-8">
                                <button
                                  onClick={() => {
                                      setStep(1);
                                      setUploadType('');
                                      setFile(null);
                                      setPredictions(null);
                                      setUploadedImage(null);
                                      setSelectedFrame(null);
                                      setTrendData(null);
                                  }}
                                  className="bg-primary hover:bg-primary-hover text-text-primary px-8 py-3 rounded-custom transition-colors font-semibold"
                                >
                                    Analyze Another Product
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          )}

          {step === 6 && <ProductSuggestionForm/>}

          <InsufficientTokensModal
            isOpen={showInsufficientTokensModal}
            onClose={() => setShowInsufficientTokensModal(false)}
          />
      </div>
    );
};

export default ObjectDetection;