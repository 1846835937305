// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiVideo, FiBox, FiLogOut, FiMenu, FiX, FiTrendingUp, FiCreditCard } from 'react-icons/fi';
import { TbZoomScan } from "react-icons/tb";
import Logo from '../assets/ProdifyMetTekst.png';
import useTokenStore from './tokenStore';
const Sidebar = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { tokens } = useTokenStore();

    const menuItems = [
        { name: 'Token Balance', path: '/dashboard/tokens', icon: <FiCreditCard className="inline mr-2" /> },
        { name: 'My Products', path: '/dashboard/my-products', icon: <FiBox className="inline mr-2" /> },
        { name: 'Analyze Product', path: '/dashboard/object-detection', icon: <TbZoomScan className="inline mr-2" /> },
        { name: 'Trending Products', path: '/dashboard/trending', icon: <FiTrendingUp className="inline mr-2" /> },
    ];

    const isActive = (path) => location.pathname === path;

    return (
      <>
          {/* Mobile Header with Logo and Menu Button */}
          <div className="md:hidden flex items-center justify-between bg-background-secondary text-text-primary p-4 fixed w-full z-20 h-[92px]">
              <div className="flex-1"></div>
              <Link to="/" className="flex-1 flex justify-center">
                  <img
                    src={Logo}
                    alt="TikLink Logo"
                    className="h-20 w-auto"
                  />
              </Link>
              <div className="flex-1 flex justify-end">
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="text-text-primary hover:text-primary transition-colors"
                  >
                      {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                  </button>
              </div>
          </div>

          {/* Sidebar */}
          <div className={`fixed md:top-0 top-[92px] left-0 h-[calc(100%-92px)] md:h-full w-64 bg-background-secondary text-text-primary transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out z-10 flex flex-col`}>
              {/* Logo - only visible on desktop */}
              <div className="p-6 hidden md:block">
                  <Link to="/">
                      <img src={Logo} alt="TikLink Logo" className="h-auto w-auto" />
                  </Link>
              </div>

              {/* Token Display - adjust visibility for mobile/desktop */}
              <div className="px-6 py-2 mb-4 md:mt-0 mt-4">
                  <div className="flex items-center justify-between text-text-secondary">
                      <span>Available Tokens</span>
                      <span className="font-semibold text-primary">{tokens || 0} tokens</span>
                  </div>
              </div>

              {/* Menu Items */}
              <nav className="flex-grow">
                  {menuItems.map((item, index) => (
                    <Link
                      key={index}
                      to={item.path}
                      onClick={() => setIsOpen(false)}
                      className={`flex items-center px-6 py-2 transition-colors duration-200 transform hover:bg-primary hover:text-text-primary ${
                        isActive(item.path) ? 'bg-primary text-text-primary' : 'text-text-secondary'
                      }`}
                    >
                        {item.icon}
                        {item.name}
                    </Link>
                  ))}
              </nav>

              {/* Logout Button */}
              <div className="p-4">
                  <Link
                    to="/logout"
                    onClick={() => setIsOpen(false)}
                    className="flex items-center px-6 py-2 transition-colors duration-200 transform hover:bg-error text-text-secondary hover:text-text-primary w-full"
                  >
                      <FiLogOut className="inline mr-2" />
                      Logout
                  </Link>
              </div>
          </div>
      </>
    );
};

export default Sidebar;